/* eslint-disable max-len */
import { ReactElement } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import WhatsappIcon from '../assets/whatsapp.png';

type Props = {
  header: ReactElement;
  body: ReactElement;
  footer: ReactElement;
};

function Page({ header, body, footer }: Props): ReactElement {
  return (
    <HelmetProvider>
      <div className="flex flex-col bg-gradient-to-r from-gray-400 via-white to-gray-400">
        <Helmet>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css"
            integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
          />
        </Helmet>
        {header}
        {body}
        {footer}
        <div
          role="button"
          tabIndex={0}
          onKeyDown={() => {
            window.location.href = 'https://wa.me/6281326606878';
          }}
          onClick={() => {
            window.location.href = 'https://wa.me/6281326606878';
          }}
          className="flex items-center justify-center fixed bottom-0 right-0 w-36 h-14 bg-brown-500 rounded-2xl mb-10 mr-4"
        >
          <img src={WhatsappIcon} className="float-left w-12 h-12" alt="" />
          <div className="text-white font-bold px-1">Order</div>
        </div>
      </div>
    </HelmetProvider>
  );
}

export default Page;
