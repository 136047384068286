/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable comma-dangle */
import { ReactElement } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Carousel,
  Typography,
} from '@material-tailwind/react';
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive';
import { ImageWithText } from './ImageWithText';
import { coffeeBeanOptions, data, updateNews } from './data';
import Kopi from '../../assets/kopi.jpeg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function CarouselTransition(): ReactElement {
  return (
    <div className="flex w-full justify-center overflow-hidden z-0">
      <Carousel
        className="md:w-full sm:w-full h-1/2 sm:h-1/3"
        placeholder=""
        autoplay={false}
        loop
        transition={{ duration: 2 }}
      >
        {data.map((element) => (
          <ImageWithText
            key={element.description}
            imgUrl={element.imgUrl as string}
            title={element.title}
            description={element.description}
            textButton={element.textButton}
            urlButton={element.urlButton}
          />
        ))}
      </Carousel>
    </div>
  );
}

function HomeGallery(): ReactElement {
  return (
    <div className="w-full flex my-6 place-content-center p-1">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
        <img
          // src="https://kurio-img.kurioapps.com/21/02/24/4f99af33-b3c1-47cd-a219-f1a5289f8b29.jpe"
          src={Kopi}
          alt=""
          className="h-50 w-full max-w-full rounded-2xl object-cover object-center"
        />
        {/* <img
          src="https://images.unsplash.com/photo-1620370219275-65b5d008f41a?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt=""
          className="h-50 w-full max-w-full rounded-2xl object-cover object-center"
        /> */}
        <img
          src="https://static.promediateknologi.id/crop/0x0:0x0/0x0/webp/photo/indizone/2022/05/23/Q8sdPPq/5-cara-masak-ayam-penyet-yang-enak-lengkap-dengan-resepnya83.jpg"
          alt=""
          className="h-50 w-full max-w-full rounded-2xl object-cover object-center"
        />
        <img
          src="https://images.unsplash.com/photo-1481833761820-0509d3217039?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt=""
          className="h-50 w-full max-w-full rounded-2xl object-cover object-center"
        />
      </div>
    </div>
  );
}

function CofeeBeanOptions(): ReactElement {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)',
  });

  const isPad = useMediaQuery({
    query: '(min-width: 768px)',
  });

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    // eslint-disable-next-line no-nested-ternary, prettier/prettier
    slidesToShow: isDesktopOrLaptop ? 4 : (isPad ? 2 : 1),
    slidesToScroll: 1,
  };

  return (
    <div className="flex flex-col mx-auto w-[70%] my-8">
      <Typography
        color="black"
        variant="h5"
        className="mt-10"
        placeholder={undefined}
      >
        These are our products:
      </Typography>
      <p className="text-gray-500 text-sm">
        We have many options for you, all of are the best quality
      </p>
      <Slider {...settings}>
        {coffeeBeanOptions.map((opt) => (
          <div className="flex w-full text-center my-2">
            <img src={opt.imgUrl} alt="" />
            <p className="font-bold py-2">{opt.title}</p>
            <p>{opt.description}</p>
            <Button
              color="yellow"
              className="rounded-none"
              placeholder={undefined}
            >
              Order
            </Button>
          </div>
        ))}
      </Slider>
    </div>
  );
}

function FollowUs(): ReactElement {
  return (
    <div className="flex flex-col bg-gradient-to-r from-brown-900 via-black to-brown-900 py-6 text-center items-center">
      <Typography
        color="yellow"
        variant="h6"
        className=""
        placeholder={undefined}
      >
        Follow Us
      </Typography>
      <div className="h-0.5 w-20 bg-yellow-600 mt-2 justify-items-center" />
      <Typography
        color="white"
        variant="h4"
        className="mt-7 mb-7 whitespace-pre-line text-lg sm:text-xl md:text-3xl"
        placeholder={undefined}
      >
        {'Have an inquiry?\nChatt or Call and ask Something.'}
      </Typography>
      <div className="flex flex-row text-white items-center font-serif font-extralight my-1">
        <i className="fa-brands fa-whatsapp mr-2" />
        <p>+62 813 2660 6878</p>
      </div>
      <div className="flex flex-row text-white items-center font-bold my-1">
        <i className="fa-regular fa-envelope mr-2" />
        <p>lukmanto79@gmail.com</p>
      </div>
    </div>
  );
}

function UpdateNews(): ReactElement {
  return (
    <div className="flex-row pt-8">
      <Typography
        variant="h3"
        color="black"
        className="text-center"
        placeholder={undefined}
      >
        Update News
      </Typography>
      <p className="text-center">
        A cup of brewed coffee represent of contribution of up to 1.5 grams
      </p>
      <div className="w-full flex place-content-center py-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:w-[90%] lg:w-[50%] mx-4">
          {updateNews.map((news) => (
            <Card className="rounded-t-lg" placeholder={undefined}>
              <CardHeader
                className="mx-0 my-0 rounded-b-none"
                placeholder={undefined}
                floated={false}
              >
                <img
                  src={news.imgUrl}
                  className="h-64 object-none"
                  alt="profile"
                />
              </CardHeader>
              <CardBody placeholder={undefined}>
                <Typography
                  variant="h4"
                  placeholder={undefined}
                  color="black"
                  className=""
                >
                  {news.title}
                </Typography>
                <Typography placeholder={undefined}>
                  {news.description}
                </Typography>
              </CardBody>
              <CardFooter placeholder={undefined}>
                <Button placeholder={undefined}>{news.textButton}</Button>
              </CardFooter>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
}

export function Body(): ReactElement {
  return (
    <div>
      <CarouselTransition />
      {/* <div className="w-full flex place-content-center">
        <div className="h-1 w-28 bg-black mt-6" />
      </div> */}
      {false && <HomeGallery />}
      <CofeeBeanOptions />
      <FollowUs />
      <UpdateNews />
    </div>
  );
}
