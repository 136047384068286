/* eslint-disable comma-dangle */
/* eslint-disable prettier/prettier */
/* eslint-disable max-len */
export const data = [
  {
    imgUrl:
      'https://images.unsplash.com/photo-1573628684835-ca186702bbde?q=80&w=1287&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    title: 'Welcome to Ndank Ngopi',
    description:
      'We are producers of Muria slope coffee with trusted quality. We process coffee in the form of raw coffee beans (beans), roasted coffee beans (roast beans) and ground coffee.',
    textButton: 'Explore',
    urlButton: 'https://www.instagram.com/ndankngopitempur/',
  },
  {
    imgUrl:
      'https://images.unsplash.com/photo-1553272711-3caf410bcb67?q=80&w=1471&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    title: 'Ndank Ngopi',
    description:
      'We are a coffee shop and restaurant with a natural feel and serving traditional village preparations.',
    textButton: '',
    urlButton: ''
  },
];

export const updateNews = [
  {
    imgUrl: 'https://unsplash.com/photos/mY4hAwxNA_0/download?ixid=M3wxMjA3fDB8MXxzZWFyY2h8MjZ8fGJlYW4lMjBjb2ZmZWV8ZW58MHx8fHwxNzE2NTM4NzM0fDA&force=true&w=640',
    title: 'Coffee beans',
    description: 'This is the example of news, where can be used for updating related the news.',
    textButton: 'Learn more',
    urlButton: '',
  },
  {
    imgUrl: 'https://unsplash.com/photos/WVl1N2C2zEA/download?ixid=M3wxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNzE2NTM2NzU5fA&force=true&w=640',
    title: 'Coffee beans',
    description: 'This is the example of news, where can be used for updating related the news.',
    textButton: 'Learn more',
    urlButton: '',
  },
  {
    imgUrl: 'https://unsplash.com/photos/obV_LM0KjxY/download?force=true&w=640',
    title: 'Coffee beans',
    description: 'This is the example of news, where can be used for updating related the news.',
    textButton: 'Learn more',
    urlButton: '',
  }
];

export const coffeeBeanOptions = [
  {
    imgUrl: 'https://unsplash.com/photos/m7ijvnNGaV0/download?ixid=M3wxMjA3fDB8MXxzZWFyY2h8NHx8Y29mZmVlJTIwYmVhbnN8ZW58MHx8fHwxNzE2NTU5MDYzfDA&force=true&w=1920',
    title: 'Robusta Tempur',
    description: '',
  },
  {
    imgUrl: 'https://unsplash.com/photos/m7ijvnNGaV0/download?ixid=M3wxMjA3fDB8MXxzZWFyY2h8NHx8Y29mZmVlJTIwYmVhbnN8ZW58MHx8fHwxNzE2NTU5MDYzfDA&force=true&w=1920',
    title: 'Arabica Tempur',
    description: '',
  },
  {
    imgUrl: 'https://unsplash.com/photos/m7ijvnNGaV0/download?ixid=M3wxMjA3fDB8MXxzZWFyY2h8NHx8Y29mZmVlJTIwYmVhbnN8ZW58MHx8fHwxNzE2NTU5MDYzfDA&force=true&w=1920',
    title: 'Liberica Tempur',
    description: '',
  },
  {
    imgUrl: 'https://unsplash.com/photos/m7ijvnNGaV0/download?ixid=M3wxMjA3fDB8MXxzZWFyY2h8NHx8Y29mZmVlJTIwYmVhbnN8ZW58MHx8fHwxNzE2NTU5MDYzfDA&force=true&w=1920',
    title: 'Exelsa Tempur',
    description: '',
  },
  {
    imgUrl: 'https://unsplash.com/photos/m7ijvnNGaV0/download?ixid=M3wxMjA3fDB8MXxzZWFyY2h8NHx8Y29mZmVlJTIwYmVhbnN8ZW58MHx8fHwxNzE2NTU5MDYzfDA&force=true&w=1920',
    title: 'Luwak Tempur',
    description: '',
  }
];
