/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable comma-dangle */
import { ReactElement, useState } from 'react';
import { Button, Typography } from '@material-tailwind/react';
import { useMediaQuery } from 'react-responsive';
import CoffeIcon from '../../assets/logo.png';

function Header(): ReactElement {
  const listMenus = [
    {
      title: 'Home',
      link: '/',
    },
    {
      title: 'About Us',
      link: '/about',
    },
    {
      title: 'Review',
      link: '/review',
    },
    {
      title: 'Contact',
      link: '/contact',
    },
  ];

  const [activeMenu, setActiveMenu] = useState('Home');

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)',
  });

  return (
    <div className="absolute z-10 w-full">
      <div className="flex w-full bg-brown-700 bg-transparent text-white p-1">
        <span className="flex w-9" />
        <div
          id="logo-with-title"
          className="flex flex-col items-center p-1 w-full"
        >
          <img src={CoffeIcon} alt="" className="h-20 w-[85px] p-1" />
        </div>
        {isDesktopOrLaptop ? (
          <ul className="flex w-full items-center gap-6">
            {listMenus.map((menu) => (
              <Typography
                placeholder=""
                as="li"
                variant="small"
                color={`${activeMenu === menu.title ? 'yellow' : 'white'}`}
                className="p-1 font-bold"
              >
                <a
                  href={menu.link}
                  onClick={() => {
                    setActiveMenu(menu.title);
                  }}
                >
                  {menu.title}
                </a>
              </Typography>
            ))}
          </ul>
        ) : (
          <div className="flex w-full items-center justify-center">
            <a href="https://wa.me/6281326606878">
              <Button
                color="white"
                variant="outlined"
                className="rounded-full"
                placeholder=""
              >
                Contact Us
              </Button>
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
